import { Component, Inject, Vue } from "vue-property-decorator";
import { CONTAINER_TYPES } from "@/vue/infrastructure/ioc/container-types";
import ServiceInterface from "@/vue/service/service-interface";
import Utils from "@/vue/infrastructure/utils/helpers";
import Swal from "sweetalert2";

@Component({
  components: {
    DfLoadingMask: () => import(/* webpackChunkName: 'df-loading-mask' */ "@/vue/components/df-loading-mask/df-loading-mask.vue"),
  },
})
export default class DfFormPasswordChangeComponent extends Vue {
  @Inject(CONTAINER_TYPES.ServiceInterface)
  private service!: ServiceInterface;

  private oldPassword: string = null;
  private newPassword: string = null;
  private confirmPassword: string = null;
  private formError: any = {};
  private working = false;
  private oldPasswordFieldType: "password" | "text" = "password";
  private oldPasswordObfuscated = true;
  private oldPasswordObfuscatedIcon: "fa-eye" | "fa-eye-slash" = "fa-eye";
  private newPasswordFieldType: "password" | "text" = "password";
  private newPasswordObfuscated = true;
  private newPasswordObfuscatedIcon: "fa-eye" | "fa-eye-slash" = "fa-eye";
  private confirmPasswordFieldType: "password" | "text" = "password";
  private confirmPasswordObfuscated = true;
  private confirmPasswordObfuscatedIcon: "fa-eye" | "fa-eye-slash" = "fa-eye";

  private changePassword() {
    this.formError = [];
    this.working = true;
    this.service
      .changePassword({oldPassword: this.oldPassword, newPassword: this.newPassword, confirmPassword: this.confirmPassword})
      .then((response: any) => {
        Swal.fire({
          title: this.$t("df-form-password-change.modal.save.title").toString(),
          text: this.$t("df-form-password-change.modal.save.body").toString(),
          icon: "info",
          confirmButtonColor: "#3085d6",
          confirmButtonText: this.$t("df-form-password-change.modal.save.ok").toString(),
        }).then((result) => {
          if (result.value) {
            this.$emit("changedPassword");
            this.working = false;
          }
        });
      })
      .catch((error: any) => {
        this.working = false;
        if (error.response.data.errors) {
          this.formError = [];
          error.response.data.errors.map((errorData: any) => {
            this.formError[errorData.field] = `${errorData.objectName}-${errorData.code}`;
            console.log(`${errorData.objectName}-${errorData.code}`, this.$t(`${errorData.objectName}-${errorData.code}`));
          });
        } else {
          Utils.defaultApiErrorHandler(error, this);
        }
      });
  }

  private showForgottenPassword() {
    this.$emit("showForgottenPassword");
  }

  private changeOldPasswordFieldType() {
    this.oldPasswordObfuscated = !this.oldPasswordObfuscated;
    this.oldPasswordObfuscatedIcon = this.oldPasswordObfuscatedIcon == "fa-eye" ? "fa-eye-slash" : "fa-eye";
    this.oldPasswordFieldType = this.oldPasswordFieldType == "text" ? "password" : "text";
  }

  private changeNewPasswordFieldType() {
    this.newPasswordObfuscated = !this.newPasswordObfuscated;
    this.newPasswordObfuscatedIcon = this.newPasswordObfuscatedIcon == "fa-eye" ? "fa-eye-slash" : "fa-eye";
    this.newPasswordFieldType = this.newPasswordFieldType == "text" ? "password" : "text";
  }

  private changeConfirmPasswordFieldType() {
    this.confirmPasswordObfuscated = !this.confirmPasswordObfuscated;
    this.confirmPasswordObfuscatedIcon = this.confirmPasswordObfuscatedIcon == "fa-eye" ? "fa-eye-slash" : "fa-eye";
    this.confirmPasswordFieldType = this.confirmPasswordFieldType == "text" ? "password" : "text";
  }
}
